




















import { computed, defineComponent, onMounted, ref, toRefs } from '@vue/composition-api';
import { useApi } from '@/util/useApi';
import { useMainStore } from '@/store';
import { getStudyResourceDetail } from './api';
import Recommend from '@/components/Recommend.vue';

export default defineComponent({
  components: { Recommend },
  setup(_, { root }) {
    const { state } = useMainStore();
    const { result: detail, refresh } = useApi(
      (id: number) => getStudyResourceDetail(id),
      null,
      false,
    );

    onMounted(() => {
      if (typeof root.$route.params.id !== 'undefined') {
        refresh(Number(root.$route.params.id));
      }
    });

    const content = computed(() =>
      detail.value?.content.replaceAll('static.zhucc.cc', 'ststatic.yjhmittc.com'),
    );

    return {
      detail,
      content,
      refresh,
      ...toRefs(state),
    };
  },
});
